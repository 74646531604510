/**
 * Date : 25-03-2021
 * this file contains function helper for images 
 */
/**
 * get image url by id 
 * @param {*} id 
 * @param {*} height 
 * @param {*} width 
 * @param {*} quality 
 * @returns 
 */
export const getProfileImageUrl = (id, height, width, quality) => {
    return `${process.env.NEXT_PUBLIC_IMAGE_BASE}profilepic/${id}/${height}/${width}/${quality}`
};

/**
 * get clinic image
 * @param {*} id 
 * @param {*} height 
 * @param {*} width 
 * @param {*} quality 
 * @returns 
 */
export const getClinicImageUrl = (id, height, width, quality) => {
    return `${process.env.NEXT_PUBLIC_IMAGE_BASE}clinic/pic/${id}/${height}/${width}/${quality}/`
};