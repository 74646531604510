import useTranslation from '../../intl/useTranslation';

function ReviewForm({ error, onSentimentChange, sentiment, onCommentChange, comment }) {
  // translation
  const { t } = useTranslation();

  return (
    <>
      <div className="inner-review-form">
        <div className="form-group review-satisfaction">
          {error && error.sentiment && (
            <span className="help-block">
              <strong>&#42; {t('You need to choose one of the following.')}</strong>
            </span>
          )}
          <ul className="sentiment-container">
            <li className="sentiment-wrapper">
              <span className={`icon meddy-satisfied ${sentiment == 3 ? 'sentiment-checked' : ''}`}></span>
              <input
                type="radio"
                id={`satisfied-option${sentiment}`}
                name="satisfaction"
                checked={sentiment == 3}
                value="3"
                required
                onChange={(e) => onSentimentChange(3)}
              />
              <label htmlFor={`satisfied-option${sentiment}`}>{t('Satisfied')}</label>
            </li>

            <li className="sentiment-wrapper">
              <span className={`icon meddy-neutral ${sentiment == 2 ? 'sentiment-checked' : ''}`}></span>
              <input
                type="radio"
                id={`neutral-option${sentiment}`}
                name="satisfaction"
                checked={sentiment == 2}
                value="2"
                onChange={(e) => onSentimentChange(2)}
              />
              <label htmlFor={`neutral-option${sentiment}`}>{t('Neutral')}</label>
            </li>

            <li className="sentiment-wrapper">
              <span className={`icon meddy-unsatisfied ${sentiment == 1 ? 'sentiment-checked' : ''}`}></span>
              <input
                type="radio"
                id={`unsatisfied-option${sentiment}`}
                name="satisfaction"
                checked={sentiment == 1}
                value="1"
                onChange={(e) => onSentimentChange(1)}
              />
              <label htmlFor={`unsatisfied-option${sentiment}`}>{t('Unsatisfied')}</label>
            </li>
          </ul>
          <textarea
            name="comment"
            value={comment}
            className="form-control comment-form"
            placeholder={t('Write your review here')}
            onChange={onCommentChange}
            style={{ height: '90px' }}
          ></textarea>
        </div>
      </div>
    </>
  );
}

export default ReviewForm;
