import { API, localize } from "../../utils";
import { GET_DOCTOR_FIND_BY, GET_DOCTOR_PROFILE_DATA, GET_DOCTOR_REVIEWS } from "../types/doctorProfile";

/**
 * get doctor profile data
 * @param {*} doctorName
 * @returns
 */
export const getDoctorProfileData = (auth, doctorName, locale, client_side_country) => async (dispatch) => {
  await API.getRequest(auth, localize(doctorName, client_side_country, locale))
    .then((res) =>
      dispatch({
        type: GET_DOCTOR_PROFILE_DATA,
        payload: res,
      })
    )
    .catch((err) => {
      throw err;
    });
};

/**
 * get doctor reviews
 * @param {*} doctorName
 * @param {*} params
 * @returns
 */
export const getDoctorReviews = (doctorName, params, locale, language) => async (dispatch) => {
  const data = await API.getRequest(
    true,
    localize(
      `api/get_reviews/${doctorName}/?page=${params.page}&sortBy=${params.sortBy}&featured=true`,
      locale,
      language
    )
  );
  dispatch({
    type: GET_DOCTOR_REVIEWS,
    payload: data,
  });
};

/**
 * get doctor find by data
 * @param {*} doctorSlug
 * @returns
 */
export const getFindDoctor = (auth, locale, language, doctorName) => async (dispatch) => {
  const data = await API.postRequest(auth, localize(`doctor/find/`, locale, language), {
    doctor_slug: doctorName,
  });
  dispatch({
    type: GET_DOCTOR_FIND_BY,
    payload: data,
  });
};

export const getDoctorFirstAvailable = async (id, client_side_country, locale) => {
    try {
        const url = `api/doctor/first_availability/${id}/`
        const data = await SSR.getRequest(localize(url, client_side_country, locale));
        return data
    } catch (error) {
        // throw new Error('An error occurred while fetching the data.')
        
        // console.log(error)
    }
  
//   if (!data) {
//     return error
//   }

  
}
