import { useSelector } from "react-redux";
import { getProfileImageUrl } from "../../helpers/images";
import useTranslation from "../../intl/useTranslation";
import { trackReadDoctorReview } from "../../services/tracker/discounts";
import Rating from "../rating";

// gender
export const GENDER = Object.freeze({
  MALE: "Male",
  FEMALE: "Female",
});

export const DoctorCardDiscount = ({
  eleIndex,
  val,
  chooseDoctor,
  chosenDoctor,
  checkReview,
  clinicName,
  discountName,
}) => {
  const { name, nameAr, name_en, rating, slug, sub_specialization, specialization, titleAr, title_en, languages } = val;
  const { language_code } = useSelector(({ InitialDataS }) => InitialDataS);
  const doctorReviews = useSelector(({ doctorReviews }) => doctorReviews);
  const lengthDoctorReviews = doctorReviews[slug].reviews.length;
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`doctor-card-discount ${chosenDoctor === val ? "chosen-card" : ""} ${
          eleIndex === 0 ? "border-y" : ""
        }`}
      >
        <div className="choose-doctor-card">
          {chosenDoctor === val.name && <div className="vertical-line-chosen"></div>}
          <div>
            <input
              className="radio-custom"
              type="radio"
              onChange={(e) => {
                chooseDoctor(val.name);
              }}
              checked={!!(chosenDoctor === val.name)}
            />
            <span className="radio-custom-label"></span>
          </div>
          <div className="doctor-info">
            {rating > 0 ? (
              <span>
                <Rating rating={rating} numReviews={null} showNum={false} />
              </span>
            ) : null}
            <h4>{language_code === "ar" ? `${titleAr} ${nameAr}` : `${title_en} ${name_en}`}</h4>
            <h6>{sub_specialization || specialization}</h6>
            <h6 style={{ marginBottom: "5px" }}>
              <i className="meddy-speech-bubble" />
              {languages.map((language, index) =>
                languages.length === index + 1 ? language.name : `${language.name}, `
              )}
            </h6>
            {lengthDoctorReviews ? (
              <button
                onClick={() => {
                  checkReview(val.slug);
                  trackReadDoctorReview(discountName, name_en, clinicName);
                }}
              >
                {t("Read Reviews")}
              </button>
            ) : null}
          </div>
        </div>
        <img
          className="discount-doc-img"
          src={
            slug
              ? getProfileImageUrl(slug, 100, 100, 95)
              : val.gender === GENDER.MALE
              ? "/next-images/default_profile_male.svg"
              : "/next-images/default_profile_female.svg"
          }
        />
      </div>
    </>
  );
};
export const HowWork = ({ isB1G1, isCouponBooking }) => {
  const { t } = useTranslation();

  return (
    <ul className="how-work-list">
      <li>
        <span className="icon">
          <img src="/next-images/how-it-works-choose-doctor.svg" alt="choose doctor" />
        </span>
        <span className="work-details">{t("Select a doctor")}</span>
      </li>
      <div className="vertical-line"></div>

      <li>
        <span className="icon">
          <img src="/next-images/Buy.svg" alt="%" />
        </span>
        {!isB1G1 ? <span className="work-details">{t("Book the discount and pay online")}</span> : ""}
        {isB1G1 && !isCouponBooking ? (
          <span className="work-details">{t("Book the Buy 1 Get 1 Free discount and pay online")}</span>
        ) : (
          ""
        )}
        {isB1G1 && isCouponBooking ? <span className="work-details">{t("Book the free discount")}</span> : ""}
      </li>
      <div className="vertical-line"></div>

      <li>
        <span className="icon">
          <img src="/next-images/Call.svg" alt="clinic" />
        </span>
        {!isB1G1 ? <span className="work-details">{t("The clinic will confirm your appointment")}</span> : ""}
        {isB1G1 && !isCouponBooking ? (
          <span className="work-details">{t("The clinic will confirm your side of the appointment")}</span>
        ) : (
          ""
        )}
        {isB1G1 && isCouponBooking ? (
          <span className="work-details">{t("The clinic will confirm your appointment")}</span>
        ) : (
          ""
        )}
      </li>
      <div className="vertical-line"></div>

      {isB1G1 && !isCouponBooking ? (
        <>
          {" "}
          <li>
            <span className="icon small">
              <img src="/next-images/Icon_Link_Black.svg" alt="link" />
            </span>
            <span className="work-details">
              {t("You get a link to share with someone to redeem the same discount for free")}
            </span>
          </li>
          <div className="vertical-line"></div>
        </>
      ) : (
        ""
      )}

      <li>
        <span className="icon">
          <img src="/next-images/Redeem.svg" alt="%" />
        </span>
        {!isB1G1 ? <span className="work-details">{t("Visit the clinic")}</span> : ""}
        {isB1G1 && !isCouponBooking ? (
          <span className="work-details">{t("Visit the clinic and get your discount")}</span>
        ) : (
          ""
        )}
        {isB1G1 && isCouponBooking ? <span className="work-details">{t("Visit the clinic")}</span> : ""}
      </li>
    </ul>
  );
};

export const ReviewCard = ({ review }) => {
  const { name, reviews } = review;
  const { t } = useTranslation();

  return (
    <div className="discount-review">
      <div className="comment-header">
        <h5 className="comment-name">{name}</h5>
      </div>
      <div className="comment-container">
        {reviews.map(({ comment, date, sentiment }) => (
          <>
            <div className="review-info">
              <div className="status-wrapper">
                {sentiment == 3 ? (
                  <>
                    <span className="status-icon meddy-satisfied"></span>
                    <span className="review-status">Satisfied</span>
                  </>
                ) : sentiment == 2 ? (
                  <>
                    <span className="status-icon meddy-neutral"></span>
                    <span className="review-status">Neutral</span>
                  </>
                ) : sentiment == 1 ? (
                  <>
                    <span className="status-icon meddy-unsatisfied"></span>
                    <span className="review-status">Unsatisfied</span>
                  </>
                ) : null}
              </div>
              <p className="comment-date">{date}</p>
            </div>
            <p className="comment-body truncated">{comment}</p>
          </>
        ))}
      </div>
    </div>
  );
};
