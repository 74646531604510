import useTranslation from "../intl/useTranslation";

function Rating({ numReviews, rating, showNum, allowRatingSelect, numericRating }) {
  const { t } = useTranslation();
  const nearestHalf = Math.floor(rating * 2) / 2;
  const isHalf = nearestHalf % 2 == 0.5;

  var hoverRating = false;
  // const allowRatingSelect = false;
  const getRating = () => {
    if (hoverRating) {
      return hoverRating;
    }
    return nearestHalf % 2 == 0.5 ? nearestHalf - 0.5 : nearestHalf;
  };
  const hoverIn = (rating) => {
    if (allowRatingSelect) {
      rating = rating + 1;
      hoverRating = rating.toString();
    }
  };

  const hoverOut = (rating) => {
    if (allowRatingSelect) {
      hoverRating = undefined;
    }
  };
  const chooseRating = (rating) => {
    if (allowRatingSelect) {
      rating = rating + 1;
      rating = rating.toString();
      numericRating(Number(rating));
    }
  };
  return (
    <>
      {numReviews != 0 && (
        <div
          className={`rating-scheme rating-${getRating()} ${isHalf ? "half" : ""} ${numReviews == 0 ? "allEmpty" : ""}`}
        >
          {[1, 2, 3, 4, 5].map((x, index) => (
            <div
              key={index}
              className="heart"
              onClick={() => chooseRating(index)}
              onMouseOver={() => hoverIn(index)}
              onMouseLeave={() => hoverOut(index)}
            >
              <div className="half-heart"></div>
            </div>
          ))}

          {numReviews > 0 && showNum && (
            <div className="heart-number-ratings" style={{ lineHeight: "normal" }}>
              {`(${numReviews} ${t("reviews")})`}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Rating;
