/*********************************************************************************************************************
 * Date: 2021-03-06
 * Desc: this file contains image helper functions
 *********************************************************************************************************************/

// base URL for images
const BASE_URL = "https://cloud.heliumdoc.com";

/**
 * get clinic thumnail img
 * @param {*} id
 * @param {*} height
 * @param {*} width
 * @param {*} quality
 */
export const getClinicThumbnail = (id, height, width, quality) => {
  quality = quality || 95;
  const url = `/clinic/pic/${id}/${height}/${width}/${quality}`;
  return BASE_URL.concat(url);
};

/**
 * getDoctorThumbnail
 * @param {*} slug
 * @param {*} height
 * @param {*} width
 * @param {*} quality
 * @param {*} locale
 */
export const getDoctorThumbnail = (slug, height, width, quality) => {
  quality = quality || 95;
  const url = `/profilepic/${slug}/${height}/${width}/${quality}`;
  return BASE_URL.concat(url);
};
