import { Mixpanel } from "../../utils";

export const trackSeeAll = (Section, Category, Area) => {
  Mixpanel.track(`Discounts Home | See All Clicked | Button`, {
    "Discount Category Name": Section,
  });
};

export const trackPageLoaded = (mixPanelTitle, data) => {
  Mixpanel.track(`${mixPanelTitle} | Page Loaded | Event`, { ...data });
};

export const trackShareDiscount = (PageName, discountName, clinicName, speciality) => {
  Mixpanel.track(`Discount Card | Share Discount Clicked | Button`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Page Type": PageName,
    "Speciality Name": speciality || "",
  });
};

export const trackShareDiscountProfile = (discountName, clinicName) => {
  Mixpanel.track(`Discount Profile | Share Discount Clicked | Button`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
  });
};

export const trackDiscountProfileCrumb = (discountName, clinicName, crumb, data) => {
  Mixpanel.track(`Discount Profile | ${crumb} | Crumb`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    ...data,
  });
};

export const trackDiscountOfferedBy = (discountName, clinicName) => {
  Mixpanel.track(`Discount Profile | Discount Offered by Clicked | Link`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
  });
};

export const trackChooseFromReview = (discountName, clinicName, doctorName) => {
  Mixpanel.track(`Discount Profile, Reviews Modal | Choose Doctor Clicked | Button`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCouponApplied = (discountName, clinicName) => {
  Mixpanel.track(`Discount Profile | Page Loaded w/ Coupon Applied | Event`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
  });
};

export const trackRedeemError = (discountName, clinicName, doctorName) => {
  Mixpanel.track(`Discount Profile | Page Loaded w/ Redeem Error | Event`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackGoToListing = (data) => {
  Mixpanel.track(`Discounts Home | Category or Area Chosen | Button`, {
    ...data,
  });
};

export const trackDiscountListingHomeCrumb = (data) => {
  Mixpanel.track(`Discount Listing | Discounts Home Clicked | Crumb`, {
    ...data,
  });
};

export const trackDiscountListingCategoryCrumb = (data) => {
  Mixpanel.track(`Discount Listing | Category Clicked | Crumb`, {
    ...data,
  });
};

export const trackOpenDiscountProfile = (PageName, discountName, clinicName, speciality) => {
  Mixpanel.track(`Discount Card | Get Discount Clicked | Button`, {
    "Discount Name": discountName,
    "Page Type": PageName,
    "Clinic Name": clinicName,
    "Speciality Name": speciality || "",
  });
};

export const trackDiscountCardClicked = (PageName, discountName, clinicName, speciality) => {
  Mixpanel.track(`Discount Card | Card Clicked | Card`, {
    "Discount Name": discountName,
    "Page Type": PageName,
    "Clinic Name": clinicName,
    "Speciality Name": speciality || "",
  });
};
export const trackDiscountDetailsTab = (discountName, clinicName, tabClicked) => {
  Mixpanel.track("Discount Profile | Details Tab Switched | Tab", {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Tab Clicked": tabClicked,
  });
};

export const trackNextButton = (discountName, doctorName, clinicName) => {
  Mixpanel.track("Discount Profile | Next Clicked | Button", {
    "Discount Name": discountName,
    "Doctor Name": doctorName,
    "Clinic Name": clinicName,
  });
};

export const trackReadDoctorReview = (discountName, doctorName, clinicName) => {
  Mixpanel.track("Discount Profile | Reviews Modal Opened | Modal", {
    "Discount Name": discountName,
    "Doctor Name": doctorName,
    "Clinic Name": clinicName,
  });
};

export const trackViewClinicGoogleMap = (discountName, clinicName) => {
  Mixpanel.track("Discounts | Discount Profile | Button | View Clinic on Google Maps", {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
  });
};
export const trackChooseDoctor = (discountName, doctorName, clinicName) => {
  Mixpanel.track("Discount Profile, Reviews Modal | Choose Doctor Clicked | Button", {
    "Discount Name": discountName,
    "Doctor Name": doctorName,
    "Clinic Name": clinicName,
  });
};

export const trackGetDiscountMobileSticky = (discountName, clinicName, doctorName) => {
  Mixpanel.track("Discount Profile | Sticky Get Discount Clicked | Button (mobile)", {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCloseDoctorsModal = (discountName, clinicName, doctorName) => {
  Mixpanel.track(`Discount Profile | Choose Doctor Modal Closed | Modal (mobile)`, {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
    "Doctor Name": doctorName,
  });
};

export const trackCloseDoctorsModalOverlay = (discountName, clinicName) => {
  Mixpanel.track("Discounts | Discount Profile, Choose Doctor Modal | Overlay | Close Modal", {
    "Discount Name": discountName,
    "Clinic Name": clinicName,
  });
};

export const trackBackDoctorReview = (discountName, doctorName, clinicName) => {
  Mixpanel.track("Discount Profile, Reviews Modal | Back Clicked | Button (mobile)", {
    "Discount Name": discountName,
    "Doctor Name": doctorName,
    "Clinic Name": clinicName,
  });
};

export const trackCloseReviewOverLay = (discountName, doctorName, clinicName) => {
  Mixpanel.track("Discount Profile | Reviews Modal Closed | Modal", {
    "Discount Name": discountName,
    "Doctor Name": doctorName,
    "Clinic Name": clinicName,
  });
};

export const trackCloseReview = (discountName, doctorName, clinicName) => {
  Mixpanel.track("Discounts | Discount Profile, Reviews Modal | Button | Close Modal Using X", {
    "Discount Name": discountName,
    "Doctor Name": doctorName,
    "Clinic Name": clinicName,
  });
};
