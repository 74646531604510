import { API, localize } from "../utils";

export const deleteReviewApi = async (reviewId, client_side_country, locale) => {
    const data = await API.postRequest(true, localize("disable_review/", client_side_country, locale), {
        id: reviewId,
    });
    return data;
};

export const editReview = async (sentiment, comment, reviewId, client_side_country, locale) => {
    const userData = await JSON.parse(localStorage?.getItem("userData"));

    const data = await API.postRequest(userData.isAuthenticated, localize("edit_review/", client_side_country, locale), {
        id: reviewId,
        comment: comment,
        sentiment: sentiment,
    });
    return data;
};

export const postDoctorReview = (doctorName) => async (dispatch) => {
    const data = await API.postRequest(false, `submit_review/${doctorName}`);
    return data ;
};
