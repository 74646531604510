import useTranslation from "../intl/useTranslation";
import { MeddyModal } from "./Common";

function ConfirmModal({ title, body, closeConfirm, failure, success }) {
    const { t } = useTranslation();

    return (
      <MeddyModal close={closeConfirm} customClassName={["cancellation-modal", "meddymodalactive"]}>
        <div className="meddy-modal-heading">
          <h2>{title}</h2>
          <i className="meddy-close cancel-icon-modal" onClick={closeConfirm} aria-hidden="true"></i>
        </div>
        <div className="meddy-modal-body">
          <p>{body}</p>
        </div>
        <div className="meddy-modal-footer confirm-footer">
          <button type="button" className="button-default-dark cancel-btn" onClick={failure}>
            {t("No")}
          </button>
          <button type="button" className="button-default-dark" type="submit" onClick={success} name="button">
            {t("Yes")}
          </button>
        </div>
      </MeddyModal>
    );
}

export default ConfirmModal;
